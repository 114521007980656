import React, { Suspense, lazy } from "react";
import { client } from "./config/apollo";
import { ApolloProvider } from "@apollo/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";

const Login = lazy(() => import("./pages/login"));

export default function Root(props) {
  return (
    <ApolloProvider client={client}>
      <BrowserRouter>
        <Suspense fallback={<></>}>
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="*" element={<Login />} />
          </Routes>
        </Suspense>
      </BrowserRouter>
    </ApolloProvider>
  );
}

